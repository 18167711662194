import React, { useReducer } from 'react'
import { GENERIC_PRODUCTS, SPECIFIC_PRODUCTS, LOCAL_PRODUCTS, RESORTS, RESELLERS, COUNTRIES, REGIONS, DOMAINS, DURATIONS, PRODUCT_SECTIONS, RESORT_PARTNERS, GUARANTEES, EXCLUSIONS, VOUCHERS, LEGACY_PRODUCTS } from '../../constants/collections'
import { createDocument, deleteDocument, fetchCollection, updateBatch, updateDocument } from '../../services/firebase'
import { SET_GENERIC_PRODUCTS, SET_SPECIFIC_PRODUCTS, SET_LOCAL_PRODUCTS, SET_RESORTS, SET_RESELLERS, SET_COUNTRIES, SET_REGIONS, SET_DOMAINS, SET_DURATIONS, SET_PRODUCT_SECTIONS, SET_RESORT_PARTNERS, SET_GUARANTEES, SET_EXCLUSIONS, SET_VOUCHERS, SET_LEGACY_PRODUCTS } from '../types'
import productsReducer from './productsReducer'

const ProductsContext = React.createContext()

const ProductsState = ({ children }) => {

  const initialState = {
    legacyProducts: [],
    legacyProductsLoaded: false,
    productSections: [],
    productSectionsLoaded: false,
    resellers: [],
    resellersLoaded: false,
    countries: [],
    countriesLoaded: false,
    regions: [],
    regionsLoaded: false,
    resorts: [],
    resortsLoaded: false,
    domains: [],
    domainsLoaded: false,
    durations: [],
    durationsLoaded: false,
    resortPartners: [],
    resortPartnersLoaded: false,
    genericProducts: [],
    genericProductsLoaded: false,
    specificProducts: [],
    specificProductsLoaded: false,
    localProducts: [],
    localProductsLoaded: false,
    guarantees: [],
    guaranteesLoaded: false,
    exclusions: [],
    exclusionsLoaded: false,
    vouchers: [],
    vouchersLoaded: false,
  }

  const [state, dispatch] = useReducer(productsReducer, initialState)

  const PRODUCT_TYPE_COLLECTION = {
    generic: GENERIC_PRODUCTS,
    specific: SPECIFIC_PRODUCTS,
    local: LOCAL_PRODUCTS,
    legacy: LEGACY_PRODUCTS
  }

  const PRODUCT_TYPE_SETTER = {
    generic: SET_GENERIC_PRODUCTS,
    specific: SET_SPECIFIC_PRODUCTS,
    local: SET_LOCAL_PRODUCTS,
    legacy: SET_LEGACY_PRODUCTS
  }

  const PRODUCT_TYPE_STATE_KEY = {
    generic: 'genericProducts',
    specific: 'specificProducts',
    local: 'localProducts',
    legacy: 'legacyProducts'
  }

  const sortArrayBy = (array, key) => {
    array.sort((a, b) => {
      const keyComponents = key.split('.')
      let valA = a
      let valB = b
      keyComponents.forEach(k => {
        valA = valA?.[k] || ''
        valB = valB?.[k] || ''
      })
      valA = valA ? valA.toLowerCase() : ''
      valB = valB ? valB.toLowerCase() : ''
      return valA.localeCompare(valB)
    })
  }

  const fetchProducts = async (type) => {
    const snapshot = await fetchCollection(PRODUCT_TYPE_COLLECTION[type])
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    array.sort((a, b) => {
      const valA = a.name?.fr ? a.name.fr.toLowerCase() : ''
      const valB = b.name?.fr ? b.name.fr.toLowerCase() : ''
      return valA.localeCompare(valB)
    })
    setProducts(type, array)
  }

  const createProduct = async (type, data) => {
    try {
      delete data.id // remove id if it exists (in case of duplicate)
      const snapshot = await createDocument(PRODUCT_TYPE_COLLECTION[type], data)
      setProducts(type, [
        { ...data, id: snapshot.id },
        ...state[PRODUCT_TYPE_STATE_KEY[type]]
      ])
      return { id: snapshot.id }
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const updateMultipleProducts = async (type, data) => {
    const promises = []
    for(let productId in data) {
      promises.push(updateDocument(`${PRODUCT_TYPE_COLLECTION[type]}/${productId}`, data[productId]))
    }
    await Promise.all(promises)
    let array = [...state[PRODUCT_TYPE_STATE_KEY[type]]]
    for(let productId in data) {
      let index = array.findIndex(s => s.id === productId)
      array[index] = {
        ...array[index],
        ...data[productId],
        id: productId
      }
    }
    setProducts(type, array)
  }

  const updateProduct = async (type, id, data) => {
    try {
      await updateDocument(`${PRODUCT_TYPE_COLLECTION[type]}/${id}`, data)
      let array = [...state[PRODUCT_TYPE_STATE_KEY[type]]]
      let index = array.findIndex(s => s.id === id)
      array[index] = {
        ...array[index],
        ...data,
        id
      }
      setProducts(type, array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const deleteProduct = async (type, id) => {
    if(type === 'generic') {
      // check if any specific products are using this generic product
      if(!state.specificProductsLoaded) {
        return { error: 'Specific products not loaded, try again later' }
      }
      if(state.specificProducts.find(s => s.generic_product === id)) {
        return { error: `Cannot delete generic product as it is being used by specific products: ${state.specificProducts.filter(s => s.generic_product === id).map(s => s.name.fr).join(', ') }` }
      }
    } else if(type === 'specific') {
      // check if any local products are using this specific product
      if(!state.localProductsLoaded) {
        return { error: 'Local products not loaded, try again later' }
      }
      if(state.localProducts.find(s => s.specific_product === id)) {
        return { error: `Cannot delete specific product as it is being used by local products: ${state.localProducts.filter(s => s.specific_product === id).map(s => s.id).join(', ') }` }
      }
    }
    try {
      await deleteDocument(`${PRODUCT_TYPE_COLLECTION[type]}/${id}`)
      let array = [...state[PRODUCT_TYPE_STATE_KEY[type]]]
      let index = array.findIndex(s => s.id === id)
      array.splice(index, 1)
      setProducts(type, array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const setProducts = (type, value) => {
    sortArrayBy(value, 'name.fr')
    dispatch({
      type: PRODUCT_TYPE_SETTER[type],
      payload: value
    })
  }

  const fetchResorts = async () => {
    const snapshot = await fetchCollection(RESORTS)
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    setResorts(array)
  }

  const fetchDomains = async () => {
    const snapshot = await fetchCollection(DOMAINS)
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    setDomains(array)
  }

  const createDomain = async (data) => {
    try {
      const snapshot = await createDocument(DOMAINS, data)
      setDomains([
        ...state.domains,
        { ...data, id: snapshot.id }
      ])
      return { id: snapshot.id }
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const updateDomain = async (id, data) => {
    try {
      await updateDocument(`${DOMAINS}/${id}`, data)
      let array = [...state.domains]
      let index = array.findIndex(s => s.id === id)
      array[index] = {
        ...array[index],
        ...data,
        id
      }
      setDomains(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const updateDomainsBatch = async (data) => {
    try {
      const updatesMap = {}
      for(let id in data) {
        updatesMap[`${DOMAINS}/${id}`] = data[id]
      }
      await updateBatch(updatesMap)
      let array = [...state.domains]
      for(let id in data) {
        let index = array.findIndex(s => s.id === id)
        array[index] = {
          ...array[index],
          ...data[id],
          id
        }
      }
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const deleteDomain = async (id) => {
    try {
      await deleteDocument(`${DOMAINS}/${id}`)
      let array = [...state.domains]
      let index = array.findIndex(s => s.id === id)
      array.splice(index, 1)
      setDomains(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const fetchDurations = async () => {
    const snapshot = await fetchCollection(DURATIONS)
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    setDurations(array)
  }

  const createDuration = async (data) => {
    try {
      const snapshot = await createDocument(DURATIONS, data)
      setDurations([
        ...state.durations,
        { ...data, id: snapshot.id }
      ])
      return { id: snapshot.id }
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const updateDuration = async (id, data) => {
    try {
      await updateDocument(`${DURATIONS}/${id}`, data)
      let array = [...state.durations]
      let index = array.findIndex(s => s.id === id)
      array[index] = {
        ...array[index],
        ...data,
        id
      }
      setDurations(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const deleteDuration = async (id) => {
    try {
      await deleteDocument(`${DURATIONS}/${id}`)
      let array = [...state.durations]
      let index = array.findIndex(s => s.id === id)
      array.splice(index, 1)
      setDurations(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const createResort = async (data) => {
    try {
      const snapshot = await createDocument(RESORTS, data)
      setResorts([
        ...state.resorts,
        { ...data, id: snapshot.id }
      ])
      return { id: snapshot.id }
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const updateResort = async (id, data) => {
    try {
      await updateDocument(`${RESORTS}/${id}`, data)
      let array = [...state.resorts]
      let index = array.findIndex(s => s.id === id)
      array[index] = {
        ...array[index],
        ...data,
        id
      }
      setResorts(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const deleteResort = async (id) => {
    try {
      await deleteDocument(`${RESORTS}/${id}`)
      let array = [...state.resorts]
      let index = array.findIndex(s => s.id === id)
      array.splice(index, 1)
      setResorts(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const setResorts = (value) => {
    sortArrayBy(value, 'name.fr')
    dispatch({
      type: SET_RESORTS,
      payload: value
    })
  }

  const setDomains = (value) => {
    dispatch({
      type: SET_DOMAINS,
      payload: value
    })
  }

  const setDurations = (value) => {
    dispatch({
      type: SET_DURATIONS,
      payload: value
    })
  }

  const fetchResellers = async () => {
    const snapshot = await fetchCollection(RESELLERS)
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    setResellers(array)
  }

  const createReseller = async (data) => {
    try {
      const snapshot = await createDocument(RESELLERS, data)
      setResellers([
        ...state.resellers,
        { ...data, id: snapshot.id }
      ])
      return { id: snapshot.id }
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const updateReseller = async (id, data) => {
    try {
      await updateDocument(`${RESELLERS}/${id}`, data)
      let array = [...state.resellers]
      let index = array.findIndex(s => s.id === id)
      array[index] = {
        ...array[index],
        ...data,
        id
      }
      setResellers(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const deleteReseller = async (id) => {
    try {
      await deleteDocument(`${RESELLERS}/${id}`)
      let array = [...state.resellers]
      let index = array.findIndex(s => s.id === id)
      array.splice(index, 1)
      setResellers(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const setResellers = (value) => {
    sortArrayBy(value, 'company_name')
    dispatch({
      type: SET_RESELLERS,
      payload: value
    })
  } 

  const fetchResortPartners = async () => {
    const snapshot = await fetchCollection(RESORT_PARTNERS)
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    setResortPartners(array)
  }

  const createResortPartner = async (data) => {
    try {
      const snapshot = await createDocument(RESORT_PARTNERS, data)
      setResortPartners([
        ...state.resortPartners,
        { ...data, id: snapshot.id }
      ])
      return { id: snapshot.id }
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const updateResortPartner = async (id, data) => {
    try {
      await updateDocument(`${RESORT_PARTNERS}/${id}`, data)
      let array = [...state.resortPartners]
      let index = array.findIndex(s => s.id === id)
      array[index] = {
        ...array[index],
        ...data,
        id
      }
      setResortPartners(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const deleteResortPartner = async (id) => {
    try {
      await deleteDocument(`${RESORT_PARTNERS}/${id}`)
      let array = [...state.resortPartners]
      let index = array.findIndex(s => s.id === id)
      array.splice(index, 1)
      setResortPartners(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const setResortPartners = (value) => {
    dispatch({
      type: SET_RESORT_PARTNERS,
      payload: value
    })
  }

  const fetchCountries = async () => {
    const snapshot = await fetchCollection(COUNTRIES)
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    setCountries(array)
  }

  const createCountry = async (data) => {
    try {
      const snapshot = await createDocument(COUNTRIES, data)
      setCountries([
        ...state.countries,
        { ...data, id: snapshot.id }
      ])
      return { id: snapshot.id }
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const updateCountry = async (id, data) => {
    try {
      await updateDocument(`${COUNTRIES}/${id}`, data)
      let array = [...state.countries]
      let index = array.findIndex(s => s.id === id)
      array[index] = {
        ...array[index],
        ...data,
        id
      }
      setCountries(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const deleteCountry = async (id) => {
    try {
      await deleteDocument(`${COUNTRIES}/${id}`)
      let array = [...state.countries]
      let index = array.findIndex(s => s.id === id)
      array.splice(index, 1)
      setCountries(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const setCountries = (value) => {
    dispatch({
      type: SET_COUNTRIES,
      payload: value
    })
  } 

  const fetchRegions = async () => {
    const snapshot = await fetchCollection(REGIONS)
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    setRegions(array)
  }

  const createRegion = async (data) => {
    try {
      const snapshot = await createDocument(REGIONS, data)
      setRegions([
        ...state.regions,
        { ...data, id: snapshot.id }
      ])
      return { id: snapshot.id }
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const updateRegion = async (id, data) => {
    try {
      await updateDocument(`${REGIONS}/${id}`, data)
      let array = [...state.regions]
      let index = array.findIndex(s => s.id === id)
      array[index] = {
        ...array[index],
        ...data,
        id
      }
      setRegions(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const deleteRegion = async (id) => {
    try {
      await deleteDocument(`${REGIONS}/${id}`)
      let array = [...state.regions]
      let index = array.findIndex(s => s.id === id)
      array.splice(index, 1)
      setRegions(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const setRegions = (value) => {
    dispatch({
      type: SET_REGIONS,
      payload: value
    })
  }

  const fetchProductSections = async () => {
    const snapshot = await fetchCollection(PRODUCT_SECTIONS)
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    setProductSections(array)
  }

  const createProductSection = async (data) => {
    try {
      const snapshot = await createDocument(PRODUCT_SECTIONS, data)
      setProductSections([
        ...state.productSections,
        { ...data, id: snapshot.id }
      ])
      return { id: snapshot.id }
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const updateProductSection = async (id, data) => {
    try {
      await updateDocument(`${PRODUCT_SECTIONS}/${id}`, data)
      let array = [...state.productSections]
      let index = array.findIndex(s => s.id === id)
      array[index] = {
        ...array[index],
        ...data,
        id
      }
      setProductSections(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const deleteProductSection = async (id) => {
    try {
      await deleteDocument(`${PRODUCT_SECTIONS}/${id}`)
      let array = [...state.productSections]
      let index = array.findIndex(s => s.id === id)
      array.splice(index, 1)
      setProductSections(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const setProductSections = (value) => {
    dispatch({
      type: SET_PRODUCT_SECTIONS,
      payload: value
    })
  }

  const fetchGuarantees = async () => {
    const snapshot = await fetchCollection(GUARANTEES)
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    setGuarantees(array)
  }

  const setGuarantees = (value) => {
    sortArrayBy(value, 'title.fr')
    dispatch({
      type: SET_GUARANTEES,
      payload: value
    })
  }

  const createGuarantee = async (data) => {
    try {
      const snapshot = await createDocument(GUARANTEES, data)
      setGuarantees([
        ...state.guarantees,
        { ...data, id: snapshot.id }
      ])
      return { id: snapshot.id }
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const updateGuarantee = async (id, data) => {
    try {
      await updateDocument(`${GUARANTEES}/${id}`, data)
      let array = [...state.guarantees]
      let index = array.findIndex(s => s.id === id)
      array[index] = {
        ...array[index],
        ...data,
        id
      }
      setGuarantees(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const deleteGuarantee = async (id) => {
    try {
      await deleteDocument(`${GUARANTEES}/${id}`)
      let array = [...state.guarantees]
      let index = array.findIndex(s => s.id === id)
      array.splice(index, 1)
      setGuarantees(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const fetchExclusions = async () => {
    const snapshot = await fetchCollection(EXCLUSIONS)
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    setExclusions(array)
  }

  const setExclusions = (value) => {
    sortArrayBy(value, 'title.fr')
    dispatch({
      type: SET_EXCLUSIONS,
      payload: value
    })
  }

  const createExclusion = async (data) => {
    try {
      const snapshot = await createDocument(EXCLUSIONS, data)
      setExclusions([
        ...state.exclusions,
        { ...data, id: snapshot.id }
      ])
      return { id: snapshot.id }
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const updateExclusion = async (id, data) => {
    try {
      await updateDocument(`${EXCLUSIONS}/${id}`, data)
      let array = [...state.exclusions]
      let index = array.findIndex(s => s.id === id)
      array[index] = {
        ...array[index],
        ...data,
        id
      }
      setExclusions(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const deleteExclusion = async (id) => {
    try {
      await deleteDocument(`${EXCLUSIONS}/${id}`)
      let array = [...state.exclusions]
      let index = array.findIndex(s => s.id === id)
      array.splice(index, 1)
      setExclusions(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const fetchVouchers = async () => {
    const snapshot = await fetchCollection('vouchers')
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    setVouchers(array)
  }

  const setVouchers = (value) => {
    dispatch({
      type: SET_VOUCHERS,
      payload: value
    })
  }

  const createVoucher = async (data) => {
    try {
      const snapshot = await createDocument(VOUCHERS, data)
      setVouchers([
        ...state.vouchers,
        { ...data, id: snapshot.id }
      ])
      return { id: snapshot.id }
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const updateVoucher = async (id, data) => {
    try {
      await updateDocument(`vouchers/${id}`, data)
      let array = [...state.vouchers]
      let index = array.findIndex(s => s.id === id)
      array[index] = {
        ...array[index],
        ...data,
        id
      }
      setVouchers(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const deleteVoucher = async (id) => {
    try {
      await deleteDocument(`vouchers/${id}`)
      let array = [...state.vouchers]
      let index = array.findIndex(s => s.id === id)
      array.splice(index, 1)
      setVouchers(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  return (
    <ProductsContext.Provider value={{
      legacyProducts: state.legacyProducts,
      legacyProductsLoaded: state.legacyProductsLoaded,
      genericProducts: state.genericProducts,
      genericProductsLoaded: state.genericProductsLoaded,
      specificProducts: state.specificProducts,
      specificProductsLoaded: state.specificProductsLoaded,
      localProducts: state.localProducts,
      localProductsLoaded: state.localProductsLoaded,
      resellers: state.resellers,
      resellersLoaded: state.resellersLoaded,
      countries: state.countries,
      countriesLoaded: state.countriesLoaded,
      resorts: state.resorts,
      resortsLoaded: state.resortsLoaded,
      domains: state.domains,
      domainsLoaded: state.domainsLoaded,
      durations: state.durations,
      durationsLoaded: state.durationsLoaded,
      regions: state.regions,
      regionsLoaded: state.regionsLoaded,
      seasons: state.seasons,
      fields: state.fields,
      productSections: state.productSections,
      productSectionsLoaded: state.productSectionsLoaded,
      resortPartners: state.resortPartners,
      resortPartnersLoaded: state.resortPartnersLoaded,
      guarantees: state.guarantees,
      guaranteesLoaded: state.guaranteesLoaded,
      exclusions: state.exclusions,
      exclusionsLoaded: state.exclusionsLoaded,
      vouchers: state.vouchers,
      vouchersLoaded: state.vouchersLoaded,
      fetchProducts,
      createProduct,
      updateProduct,
      updateMultipleProducts,
      deleteProduct,
      fetchResorts,
      fetchDomains,
      createDomain,
      updateDomain,
      updateDomainsBatch,
      deleteDomain,
      fetchDurations,
      createDuration,
      updateDuration,
      deleteDuration,
      createResort,
      updateResort,
      deleteResort,
      fetchResellers,
      createReseller,
      updateReseller,
      deleteReseller,
      fetchCountries,
      createCountry,
      updateCountry,
      deleteCountry,
      fetchRegions,
      createRegion,
      updateRegion,
      deleteRegion,
      fetchProductSections,
      createProductSection,
      updateProductSection,
      deleteProductSection,
      fetchResortPartners,
      createResortPartner,
      updateResortPartner,
      deleteResortPartner,
      fetchGuarantees,
      createGuarantee,
      updateGuarantee,
      deleteGuarantee,
      fetchExclusions,
      createExclusion,
      updateExclusion,
      deleteExclusion,
      fetchVouchers,
      createVoucher,
      updateVoucher,
      deleteVoucher,
    }}>
      { children }
    </ProductsContext.Provider>
  )
}

export { ProductsContext }

export default ProductsState