import React, {useContext, useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import ToolbarCustom from '../../../_metronic/layout/components/toolbar/toolbars/ToolbarCustom'

import { ProductsContext } from '../../context'
import DomainDetailModal from './DomainDetailModal'
import ImportCollectionModal from '../../components/ImportCollectionModal'
import config from '../../../config.json'
import DataGridComponent from '../../components/DataGrid'

const DomainsPage = () => {
  
  const { domains, domainsLoaded, fetchDomains, createDomain, updateDomain, updateDomainsBatch, deleteDomain } = useContext(ProductsContext)

  const [currentDomain, setCurrentDomain] = useState(null)
  const [loading, setLoading] = useState(false)
  const [importCollectionModalOpen, setImportCollectionModalOpen] = useState(false)

  const [originalOrder, setOriginalOrder] = useState([])
  const [updatedOrder, setUpdatedOrder] = useState([])
  const [orderedDomains, setOrderedDomains] = useState([])

  useEffect(() => {
    if(!domainsLoaded) {
      return
    }
    const orderedDomainIds = domains.sort((a, b) => {
      if(a.order !== undefined && b.order !== undefined) {
        return a.order - b.order
      } else if(a.order !== undefined) {
        return -1
      } else if(b.order !== undefined) {
        return 1
      } else {
        return (a.title?.fr || '').localeCompare(b.title?.fr || '')
      }
  }).map((domain) => domain.id)
    setUpdatedOrder(orderedDomainIds)
    setOriginalOrder(orderedDomainIds)
  }, [domains])

  useEffect(() => {
    const ordered = domains.map((domain) => ({...domain}))
    for(let i = 0; i < updatedOrder.length; i++) {
      const domain = ordered.find((domain) => domain.id === updatedOrder[i])
      if(!domain) {
        continue
      }
      domain.order = i
    }
    setOrderedDomains([...ordered.sort((a, b) => a.order - b.order)])
  }, [domains, updatedOrder])

  useEffect(() => {
    if(!domainsLoaded) {
      fetchDomains()
    }
  }, [domainsLoaded, fetchDomains])

  const onCreateClick = () => {
    setCurrentDomain({
      title: {},
      priority: false
    })
  }

  const onEditClick = (domain) => {
    setCurrentDomain(domain)
  }

  const onDetailSubmit = async () => {
    let response
    const data = {...currentDomain}
    if(!data.id) {
      response = await createDomain(data)
    } else {
      response = await updateDomain(data.id, data)
    }
    console.log("domain update response", response)
    setCurrentDomain(null)
  }

  const onDetailDelete = async () => {
    if(!confirm(`Are you sure you want to delete this activity? (${currentDomain?.title?.fr || currentDomain.id})`)) return
    let response = await deleteDomain(currentDomain.id)
    console.log("domain delete response", response)
    setCurrentDomain(null)
  }

  const promptImportCollection = () => {
    setImportCollectionModalOpen(true)
  }

  const convertToTableItem = (domain) => {
    return {
      id: domain.id,
      order: domain.order,
      title: domain.title,
      priority: domain.priority
    }
  }

  const orderChanged = originalOrder.join(',') !== updatedOrder.join(',')

  const handleSaveOrder = async () => {
    console.log("saving order", updatedOrder)
    setLoading(true)
    const updates = {}
    for(let i = 0; i < updatedOrder.length; i++) {
      const domain = domains.find((domain) => domain.id === updatedOrder[i])
      if(domain.order !== i) {
        updates[domain.id] = { order: i }
      } else {
        console.log("no change", domain.id)
      }
    }
    console.log("updates", updates)
    await updateDomainsBatch(updates)
    setLoading(false)
    setOriginalOrder([...updatedOrder])
  }

  return (
    <>
      <ToolbarCustom
        pageTitle={'Activities'}
        primaryButton={domainsLoaded && { onClick: onCreateClick, title: 'Add' }}
        loading={!domainsLoaded || loading}       
        filterElements={[(
          orderChanged && <button className='btn btn-sm fw-bold btn-primary' onClick={handleSaveOrder}>
            <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-3' />
            Save updated order
          </button>
        ), (
          config.environment !== 'staging' && <button className='btn btn-sm fw-bold btn-primary' onClick={promptImportCollection}>
            <KTSVG path='/media/icons/duotune/arrows/arr037.svg' className='svg-icon-3' />
            Import from staging
          </button>
        )]}
      />
      <div className='mt-3'>
        <DataGridComponent
          id="domains"
          rows={orderedDomains.map(convertToTableItem)}
          reorderable={true}
          onOrderChanged={setUpdatedOrder}
          columns={[
            {
              field: 'title',
              headerName: 'Title',
              flex: 2,
              valueFormatter: title => title?.fr || 'Missing title',
              sortComparator: (v1, v2) => (v1?.fr || '').localeCompare(v2?.fr || '')
            },
            {
              field: 'priority',
              headerName: 'Priority',
              width: 120,
              valueFormatter: priority => priority ? 'Yes' : 'No'
            },
            {
              field: 'id',
              headerName: 'Actions',
              width: 120,
              renderCell: (params) => (
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                  onClick={() => onEditClick(params.row)}
                >
                  <KTSVG path='/media/icons/duotune/general/gen055.svg' className='svg-icon-2' />
                </button>
              )
            }
          ]}
        />
      </div>

      <DomainDetailModal
        show={Boolean(currentDomain)}
        handleClose={() => setCurrentDomain(null)}
        domain={currentDomain}
        setDomain={setCurrentDomain}
        onSubmit={onDetailSubmit}
        onDelete={onDetailDelete} />
      <ImportCollectionModal
        isOpen={importCollectionModalOpen}
        onClose={() => setImportCollectionModalOpen(false)}
        collectionName={"domains"}
        sourceEnvironment={"staging"}
        onImportComplete={async () => {
          // reload collection
          await fetchDomains()
        }} />
    </>
  )
}

const DomainsWrapper = () => {
  return (
    <>
      <DomainsPage />
    </>
  )
}

export default DomainsWrapper
