import React, { useEffect, useRef, useState } from "react"
import { DataGrid } from "@mui/x-data-grid"
import config from "../../config.json"
import PropTypes from 'prop-types'
import { KTSVG } from "../../_metronic/helpers"

const DataGridComponent = ({
  rows = [],
  columns = [],
  id,
  pageSizeOptions = [10, 20, 50],
  reorderable = false,
  onOrderChanged = () => {
    throw new Error('onOrderChanged is not implemented')
  }
}) => {

  const defaultFilterModel = {items: [], logicOperator: "and", quickFilterLogicOperator: "and", quickFilterValues: []}
  const defaultSortModel = []
  const [filterModel, setFilterModel] = useState(defaultFilterModel)
  const [sortModel, setSortModel] = useState(defaultSortModel)
  const [, setDraggedIndex] = useState(null)
  const [, setDraggedOverIndex] = useState(null)

  const draggedIndexRef = useRef(null)
  const draggedOverIndexRef = useRef(null)

  const setCookie = (name, value, attributes = {}) => {

    attributes = {
      path: '/',
      // add other defaults here if necessary
      ...attributes
    };
  
    if (attributes.expires instanceof Date) {
      attributes.expires = attributes.expires.toUTCString();
    }
  
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
  
    for (let attributeKey in attributes) {
      updatedCookie += "; " + attributeKey;
      let attributeValue = attributes[attributeKey];
      if (attributeValue !== true) {
        updatedCookie += "=" + attributeValue;
      }
    }
  
    document.cookie = updatedCookie;
  }

  useEffect(() => {
    const getCookie = (name) => {
      let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([.$?*|{}()[]\\\/\+^])/g, '\\$1') + "=([^;]*)"
      ));
      return matches ? decodeURIComponent(matches[1]) : undefined;
    }
    const filterModelFromCookie = getCookie(`assuranceski_${config.environment}_${id}_filterModel`)
    if(filterModelFromCookie) {
      setFilterModel(JSON.parse(filterModelFromCookie))
    }
    const sortModelFromCookie = getCookie(`assuranceski_${config.environment}_${id}_sortModel`)
    if(sortModelFromCookie) {
      setSortModel(JSON.parse(sortModelFromCookie))
    }
  }, [document.cookie])

  const handleChangeFilterModel = (model) => {
    setFilterModel(model)
    setCookie(`assuranceski_${config.environment}_${id}_filterModel`, JSON.stringify(model))
  }

  const handleChangeSortModel = (model) => {
    setSortModel(model)
    setCookie(`assuranceski_${config.environment}_${id}_sortModel`, JSON.stringify(model))
  }

  const handleDragStart = (e) => {
    // set order model to use "order" field
    handleChangeSortModel([{field: 'order', sort: 'asc'}])
    if(!reorderable) {
      return
    }    
    if(!e.target.dataset.index) {
      return
    }
    draggedIndexRef.current = parseInt(e.target.dataset.index)
    setDraggedIndex(parseInt(e.target.dataset.index))
  }

  const handleClearDrag = () => {
    draggedIndexRef.current = null
    draggedOverIndexRef.current = null
    setDraggedIndex(null)
    setDraggedOverIndex(null)
  }

  const handleDragEnd = (e) => {
    if(!reorderable) {
      return
    }
    if(!e.target.dataset.index) {
      handleClearDrag()
      return
    }
    
    const draggedItemIndex = rows.findIndex((item) => item.order === draggedIndexRef.current)
    const draggedOverItemIndex = rows.findIndex((item) => item.order === draggedOverIndexRef.current)

    if(draggedItemIndex === draggedOverItemIndex) {
      handleClearDrag()
      return
    }

    const updatedRows = [...rows]
    const [draggedItem] = updatedRows.splice(draggedItemIndex, 1)
    updatedRows.splice(draggedOverItemIndex, 0, draggedItem)
    updatedRows.forEach((item, index) => {
      item.order = index
    })
    onOrderChanged(updatedRows.sort((a, b) => a.order - b.order).map((item) => item.id))
    handleClearDrag()
  }
  
  const handleDragOver = (e) => {
    if(!reorderable) {
      return
    }
    if(!e.target.dataset.index) {
      return
    }
    draggedOverIndexRef.current = parseInt(e.target.dataset.index)
    setDraggedOverIndex(parseInt(e.target.dataset.index))
  }

  const getRowClassName = (params) => {
    if(!reorderable) {
      return ''
    }
    const isDragged = params.row.order === draggedIndexRef.current
    const isDraggedOver = params.row.order === draggedOverIndexRef.current
    const moveDirection = draggedIndexRef.current < draggedOverIndexRef.current ? 'down' : 'up'
    return `reorderable-table-row${isDragged ? ' reorderable-table-row--dragging' : ''}${isDraggedOver && !isDragged ? ` reorderable-table-row--dragged-over move-${moveDirection}` : ''}`
  }


  return (
    <DataGrid
      rowSelection={false}
      rows={rows}
      sortModel={sortModel}
      filterModel={filterModel}
      onSortModelChange={handleChangeSortModel}
      onFilterModelChange={handleChangeFilterModel}
      className={`data-grid-table${reorderable ? ' data-grid-table--reorderable' : ''}${draggedIndexRef.current !== null ? ' data-grid-table--dragging' : ''}`}
      columns={[
        reorderable ? {
          field: 'order',
          headerName: 'Order',
          width: 100,
          renderCell: (params) => (
            // render drag handle & order number
            <div
              className='d-flex align-items-center reorderable-table-row--handle'
              draggable={true}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              onDragOver={handleDragOver}
              data-index={params.row.order}
              >
              <div className='me-3 pe-none'>
                <KTSVG path='/media/icons/duotune/arrows/arr032.svg' className='svg-icon-3' />
              </div>
              <div className='pe-none'>{params.row.order + 1}</div>
            </div>
          ),
          headerClassName: 'table--header-cell',
          cellClassName: 'table--body-cell',
          hide: false
        } : null,
        ...columns.map((column) => ({
          ...column,
          headerClassName: 'table--header-cell',
          cellClassName: 'table--body-cell',
        }))
      ].filter(Boolean)}
      getRowClassName={getRowClassName}
      pageSizeOptions={pageSizeOptions}
    />
  )
}
DataGridComponent.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  pageSizeOptions: PropTypes.array,
  reorderable: PropTypes.bool,
  onOrderChanged: PropTypes.func
}

export default DataGridComponent
