import React, {useContext, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import ToolbarCustom from '../../../_metronic/layout/components/toolbar/toolbars/ToolbarCustom'

import { ProductsContext } from '../../context'
import ResortDetailModal from './ResortDetailModal'
import { GridActionsCellItem } from '@mui/x-data-grid'
import ImportCollectionModal from '../../components/ImportCollectionModal'
import config from '../../../config.json'
import moment from 'moment'
import DataGridComponent from '../../components/DataGrid'

const ResortsPage = () => {
  
  const { resorts, resortsLoaded, fetchResorts, createResort, updateResort, deleteResort, regions, regionsLoaded, fetchRegions } = useContext(ProductsContext)

  const [currentResort, setCurrentResort] = useState(null)
  const [loading, setLoading] = useState(false)
  const [sortBy, setSortBy] = useState('name')
  const [sortOrder, setSortOrder] = useState('asc')
  const [filteredResorts, setFilteredResorts] = useState([])
  const [search, setSearch] = useState('')
  const [importCollectionModalOpen, setImportCollectionModalOpen] = useState(false)

  useEffect(() => {
    if(!resortsLoaded) {
      fetchResorts()
    }
  }, [resortsLoaded, fetchResorts])

  useEffect(() => {
    if(!regionsLoaded) {
      fetchRegions()
    }
  }, [regionsLoaded, fetchRegions])

  const onCreateClick = () => {
    setCurrentResort({
      id: '',
      name: {},
      description: {},
      region: '',
      country: '',
      coordinates: {
        latitude: '',
        longitude: ''
      },
    })
  }

  const onEditClick = (resort) => {
    setCurrentResort(resort)
  }

  const onDetailSubmit = async (data) => {
    if(!data.name.fr) {
      alert('Resort name is required (missing french translation)')
      return
    }
    const languages = ['en', 'es', 'it']
    for(const lang of languages) {
      if(!data.name[lang]) {
        data.name[lang] = data.name.fr
      }
    }
    if(data.coordinates.latitude === '' || data.coordinates.longitude === '') {
      alert('Coordinates are required')
      return
    }
    let response
    let resortId
    if(!data.id) {
      response = await createResort(data)
      resortId = response.id
    } else {
      resortId = data.id
      response = await updateResort(resortId, data)
    }
    
    setCurrentResort(null)
  }

  const onDetailDelete = async () => {
    if(!confirm(`Are you sure you want to delete this resort? (${currentResort?.name?.fr || currentResort.id})`)) return
    await deleteResort(currentResort.id)
    setCurrentResort(null)
  }

  const itemWithId = (array, id) => {
    return array.find(i => i.id === id)
  }

  const formatDateString = (date) => {
    if(!date) return ''
    const [day, month] = date.split('/')
    return `${day.length === 1 ? '0' + day : day}/${month.length === 1 ? '0' + month : month}`
  }

  const convertToTableItem = (resort) => {
    return {
      ...resort,
      name_label: resort.name?.fr || 'N/A',
      region_label: itemWithId(regions, resort.region)?.name?.fr || 'N/A',
      external_url_label: resort.external_url?.fr || '',
      created: resort.meta.created,
      updated: resort.meta.updated,
      winter_season_start: formatDateString(resort.winter_season?.start || ''),
      winter_season_end: formatDateString(resort.winter_season?.end || ''),
      // created_label: moment(resort.meta.created).format('DD/MM/YYYY HH:mm'),
      // updated_label: moment(resort.meta.updated).format('DD/MM/YYYY HH:mm')
    }
  }

  useEffect(() => {
    let r = [...resorts]
    if(sortBy === 'name') {
      r.sort((a, b) => {
        if(a.name.fr < b.name.fr) return sortOrder === 'asc' ? -1 : 1
        if(a.name.fr > b.name.fr) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    } else if(sortBy === 'region') {
      r.sort((resortA, resortB) => {
        const a = itemWithId(regions, resortA.region)
        const b = itemWithId(regions, resortB.region)
        if(a?.name.fr < b?.name.fr) return sortOrder === 'asc' ? -1 : 1
        if(a?.name.fr > b?.name.fr) return sortOrder === 'asc' ? 1 : -1
        return 0

      })
    }
    if(search) {
      r = r.filter(resort => resort.name.fr.toLowerCase().includes(search.toLowerCase()))
    }
    setFilteredResorts(r)
  }, [resorts, regions, sortOrder, sortBy, search])

  const promptImportCollection = () => {
    setImportCollectionModalOpen(true)
  }

  return (
    <>
      <ToolbarCustom
        pageTitle={'Resorts'}
        primaryButton={resortsLoaded && { onClick: onCreateClick, title: 'Add' }}
        filterElements={[(
        <div
          data-kt-search-element='form'
          className='w-100 position-relative'
          key="filter-element-search"
        >
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
          />
          <input
            type='text'
            className='form-control form-control-flush ps-10'
            name='search'
            value={search}
            placeholder='Search...'
            data-kt-search-element='input'
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>), (
          config.environment !== 'staging' && <button className='btn btn-sm fw-bold btn-primary' onClick={promptImportCollection}>
            <KTSVG path='/media/icons/duotune/arrows/arr037.svg' className='svg-icon-3' />
            Import from staging
          </button>
        )]}
        loading={!resortsLoaded} />
      {/* begin::Row */}
      <div className='mt-1'>
        {/* begin::Table */}
        <DataGridComponent
          id="resorts"
          rowSelection={false}
          rows={filteredResorts.map(convertToTableItem)}
          columns={[{
            field: 'name_label',
            headerName: 'Name',
            flex: 1,
          }, {
            field: 'region_label',
            headerName: 'Region',
            flex: 1,
          }, {
            field: 'winter_season_start',
            headerName: 'Winter Season Start',
            width: 150,
          }, {
            field: 'winter_season_end',
            headerName: 'Winter Season End',
            width: 150,
          // }, {
          //   field: 'external_url_label',
          //   headerName: 'External URL',
          //   flex: 1,
          }, {
            field: 'created',
            headerName: 'Created',
            flex: 1,
            valueFormatter: (value) => moment(value).format('DD/MM/YYYY HH:mm')
          }, {
            field: 'updated',
            headerName: 'Updated',
            flex: 1,
            valueFormatter: (value) => moment(value).format('DD/MM/YYYY HH:mm')
          }, {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
              return [
                <GridActionsCellItem
                  key={`edit-${id}`}
                  icon={<i className="bi bi-pencil-square text-primary"></i>}
                  label="Edit"
                  className="textPrimary"
                  onClick={() => onEditClick(itemWithId(filteredResorts, id))}
                  color="inherit"
                />,
              ];
            },
          }]}
          pageSizeOptions={[20, 50, 100]}
        />

        {/* begin::Col */}
        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget20
            className='h-md-50 mb-5 mb-xl-10'
            description='Active Projects'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
          <CardsWidget7
            className='h-md-50 mb-5 mb-xl-10'
            description='Professionals'
            icon={false}
            stats={357}
            labelColor='dark'
            textColor='gray-300'
          />
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
          <ListsWidget26 className='h-lg-50' />
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6'>
          {/* <EngageWidget10 className='h-md-100' /> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gx-5 gx-xl-10'>
        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 gx-xl-8'>
        <div className='col-xxl-4'>
          <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
        </div>
        <div className='col-xl-8'>
          <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
      </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 g-xl-8'>
        <div className='col-xl-4'>
          <ListsWidget2 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget6 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
        </div>
      </div> */}
      {/* end::Row */}

      {/* <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-4'>
          <MixedWidget8
            className='card-xxl-stretch mb-xl-3'
            chartColor='success'
            chartHeight='150px'
          />
        </div>
        <div className='col-xxl-8'>
          <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
        </div>
      </div> */}
      <ResortDetailModal
        show={Boolean(currentResort)}
        handleClose={() => setCurrentResort(null)}
        resort={currentResort}
        setResort={setCurrentResort}
        onSubmit={onDetailSubmit}
        onDelete={onDetailDelete} />

      <ImportCollectionModal
        isOpen={importCollectionModalOpen}
        onClose={() => setImportCollectionModalOpen(false)}
        collectionName={"resorts"}
        sourceEnvironment={"staging"}
        onImportComplete={async () => {
          // reload collection
          await fetchResorts()
        }} />
    </>
  )
}

const ResortsWrapper = () => {
  return (
    <>
      <ResortsPage />
    </>
  )
}

export default ResortsWrapper
